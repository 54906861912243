import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AOS from "aos";
import "@/assets/main.css";
import "aos/dist/aos.css";
import VueSmoothScroll from "vue3-smooth-scroll";
import i18n from "./i18n";

const app = createApp(App);
app.use(VueSmoothScroll);
app.use(store);
app.use(router);
app.use(i18n);

// Initialize AOS with specific settings
AOS.init({
    duration: 800,
    easing: 'ease-out',
    once: false,
    mirror: true,
    startEvent: 'DOMContentLoaded',
    offset: 50,
    delay: 0,
    anchorPlacement: 'top-bottom',
});

// Refresh AOS on route changes
router.afterEach(() => {
    setTimeout(() => {
        AOS.refresh();
    }, 100);
});

app.config.globalProperties.$scrollToEl = function (index) {
    const element = document.querySelector(index);

    this.$smoothScroll({
        scrollTo: element,
        duration: 1500,
        offset: -70,
        updateHistory: true,
    });
};

app.mount("#app");
