<template>
    <section class="fixed-bg mx-auto px-6 md:px-12 lg:px-16 py-24 text-white">
        <div class="mx-auto md:px-12 lg:px-16">
            <!-- Section Title -->
            <div class="md:flex justify-between items-center mb-12">
                <div class="md:mb-0 mb-6" data-aos="fade-up" data-aos-duration="600">
                    <h2 class="text-4xl font-bold mb-2 tracking-wider text-shadow-md font-bitter">
                        Our Solutions
                    </h2>
                    <p class="text-lg font-light font-raleway">Explore our cutting-edge solutions designed to
                        drive innovation and
                        success.</p>
                </div>
                <div data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">
                    <button @click="this.$scrollToEl('.contact')"
                        class="md:bg-yellow-500 text-yellow-500 md:border-none border-b md:text-gray-900 md:rounded-md md:px-4 md:py-3 uppercase">
                        Contact us
                    </button>
                </div>
            </div>

            <!-- Solutions Grid -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <!-- Service Cards -->
                <div v-for="(service, index) in services" :key="index"
                    class="service-card bg-white/30 text-gray-900 shadow-lg p-6" data-aos="fade-up"
                    :data-aos-delay="isDesktop ? 200 + (index * 200) : 0">
                    <div class="h-[260px] bg-white/30 mb-6">
                        <img :src="service.image" :alt="service.title"
                            class="h-full w-full object-contain md:transform md:transition-all md:duration-500 md:hover:scale-105" />
                    </div>
                    <h3 class="text-2xl font-semibold mb-4 text-yellow-500 font-bitter">{{ service.title }}</h3>
                    <p class="font-light mb-5 font-raleway leading-relaxed text-white">{{ service.description }}</p>
                    <a v-if="service.link" :href="service.link" target="_blank" rel="noopener noreferrer"
                        class="uppercase text-gray-100 border-b pb-1">
                        Learn More
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { animationMixin } from '@/mixins/animationMixin';

export default {
    name: 'ServicesComponent',
    mixins: [animationMixin],
    data() {
        return {
            services: [
                {
                    title: 'AI-Powered Software Solutions',
                    description: 'We specialize in building advanced AI-driven applications that automate business processes, streamline workflows, and enhance customer experiences...',
                    image: require('@/assets/services/data-consultancy.png'),
                    link: 'http://hovastore.com'
                },
                {
                    title: 'Data & Machine Learning Solutions',
                    description: 'Unlock the full potential of your data with our machine learning models and data-driven insights...',
                    image: require('@/assets/services/data.png')
                }
            ]
        }
    },
    computed: {
        isDesktop() {
            return window.innerWidth >= 768;
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            if (typeof AOS !== 'undefined') {
                AOS.refresh();
            }
        }
    }
};
</script>

<style scoped lang="scss">
.text-shadow-md {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.service-card {
    @media (min-width: 768px) {
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);

        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
        }
    }
}

.fixed-bg {
    background-image: url("@/assets/about.png");
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    @media (max-width: 767px) {
        background-attachment: scroll;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
    }
}
</style>