<template>
    <section class="mx-auto px-6 md:px-12 lg:px-16 py-12 bg-gray-100">
        <div class="container mx-auto px-6 grid md:grid-cols-2 gap-1">
            <div class="bg-gray-50 py-10">
                <h2 class="text-3xl md:text-4xl font-bold mb-6 font-bitter text-center leading-relaxed">
                    {{ $t(`partners.title.3`) }}
                </h2>
                <div class="flex justify-center items-center gap-6 flex-wrap">
                    <div v-for="(item, index) in clients" :key="index" class="md:p-4 text-center">
                        <img :src="item.image" :alt="item.image" class="object-contain h-24 mx-auto mb-3" />
                    </div>
                </div>
            </div>
            <div class="bg-gray-50 py-10">
                <h2 class="text-3xl md:text-4xl font-bold mb-6 font-bitter text-center leading-relaxed">
                    {{ $t(`partners.title.2`) }}
                </h2>
                <div class="flex justify-center items-center gap-6 flex-wrap">
                    <div v-for="(item, index) in partners" :key="index" class="md:p-4 text-center">
                        <img :src="item.image" :alt="item.image" class="object-contain h-24 mx-auto mb-3" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Partners",
    data() {
        return {
            partners: [],
            clients: [],
        };
    },
    mounted() {
        const requireClientImages = require.context('@/assets/clients', false, /\.(jpg|png|svg)$/);
        const requirePartnersImages = require.context('@/assets/partners', false, /\.(jpg|png|svg)$/);

        this.partners = requirePartnersImages.keys().map((fileName) => ({
            image: requirePartnersImages(fileName),
        }));

        this.clients = requireClientImages.keys().map((fileName) => ({
            image: requireClientImages(fileName),
        }));
    },
};
</script>

<style scoped></style>