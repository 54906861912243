<template>
    <section class="relative w-full h-screen overflow-hidden flex flex-col justify-center items-center">
        <!-- Loading Screen -->
        <div v-if="!videoLoaded" class="fixed inset-0 z-50 bg-gray-900 flex items-center justify-center">
            <div class="text-center flex flex-col items-center justify-center">
                <div class="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-yellow-500 mb-4"></div>
                <p class="text-white text-lg font-raleway">Loading HOVA AI...</p>
            </div>
        </div>

        <!-- Include Header -->
        <Header />

        <!-- Video Background -->
        <video ref="videoElement" autoplay loop muted playsinline preload="auto" @canplay="handleVideoLoad"
            class="absolute inset-0 w-full h-full object-cover opacity-90" poster="../assets/video-overlay.png">
            <source src="herobanner.webm" type="herobanner/webm">
            <source src="../assets/herobanner.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>

        <!-- Gradient Overlay -->
        <div class="absolute inset-0 bg-gradient-to-b from-black/30 via-white/10 to-white/5"></div>

        <!-- Content -->
        <div v-show="videoLoaded"
            class="relative z-10 flex flex-col justify-center items-center text-center text-white h-full md:px-auto px-6">
            <!-- Company Title -->
            <h1 v-if="currentScrollY === 0" data-aos="fade-down" data-aos-delay="200"
                class="text-5xl md:text-8xl font-extrabold mb-6 tracking-widest uppercase font-bitter">
                Hova <span class="text-yellow-400">AI</span>
            </h1>
            <!-- Subtitle 1 -->
            <p data-aos="fade-up" data-aos-delay="400"
                class="text-lg md:text-3xl max-w-2xl leading-relaxed mb-10 font-bodoni">
                {{ $t('herobanner.title.1') }} <span class="text-yellow-400 font-extrabold">{{ $t('herobanner.title.2')
                    }}</span> {{ $t('herobanner.title.3') }}
            </p>
            <!-- Subtitle 2 -->
            <p data-aos="fade-up" data-aos-delay="600"
                class="text-lg md:text-2xl max-w-3xl leading-relaxed mb-12 font-jungle">
                {{ $t('herobanner.subtitle') }}
            </p>
            <!-- Interactive Bubbles -->
            <div class="flex flex-wrap justify-center md:gap-6 gap-4" v-if="currentScrollY === 0">
                <div v-for="(link, index) in bubbles" :key="link.name" data-aos="zoom-in"
                    :data-aos-delay="800 + (index * 200)" class="bubble cursor-pointer uppercase text-[13px] md:text-[14px] tracking-wider text-white 
                            border border-white px-3 py-2 rounded-md hover:bg-yellow-500 hover:text-black 
                            transition-all duration-300 hover:-translate-y-1 hover:shadow-lg"
                    @click="this.$scrollToEl(link.path)">
                    {{ $t(`${link.name}`) }}
                </div>
            </div>
            <!-- Chevron Scroll Down -->

        </div>
        <div @click="this.$scrollToEl('.about')" v-if="currentScrollY === 0" data-aos="fade-up" data-aos-delay="1200"
            class="bounce text-white fixed z-20 bottom-10 border-1 bg-white h-14 w-14 rounded-full bg-opacity-5 hidden md:flex items-center justify-center cursor-pointer text-4xl md:text-6xl mt-4 hover:bg-yellow-500 hover:bg-opacity-20 transition-all duration-300">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="m19 9-7 7-7-7" />
            </svg>
        </div>
    </section>
</template>

<script>
import Header from './Header.vue';
import { useI18n } from 'vue-i18n';

export default {
    components: { Header },
    props: {
        currentScrollY: { type: Number, default: 0 }
    },
    data() {
        return {
            videoLoaded: false,
            bubbles: [
                { name: 'partners.title.3', path: ".partners" },
                { name: 'menu.2', path: ".contact" },
                { name: 'menu.3', path: ".solutions" },
            ]
        };
    },
    computed: {
        t() {
            return useI18n().t;
        }
    },
    methods: {
        handleVideoLoad() {
            // Add a small delay to ensure smooth transition
            setTimeout(() => {
                this.videoLoaded = true;
            }, 500);
        },
        scrollToSection(path) {
            if (this.$scrollToEl) {
                this.$scrollToEl(path);
            } else {
                console.error("$scrollToEl is not available.");
            }
        },
        scrollDown() {
            window.scrollTo({
                top: window.innerHeight,
                behavior: 'smooth',
            });
        }
    },
    mounted() {
        // Fallback in case video takes too long or fails to load
        setTimeout(() => {
            if (!this.videoLoaded) {
                this.videoLoaded = true;
            }
        }, 7000); // 5 second fallback
    }
};
</script>

<style scoped>
/* Bounce Animation for the Chevron */
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

.bounce {
    animation: bounce 2s infinite;
}

.bubble {
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
}

/* Add loading animation styles */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.animate-spin {
    animation: spin 1s linear infinite;
}
</style>