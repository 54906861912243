<template>
  <nav class="w-full fixed top-0 z-20">
    <div
      :class="`px-8 py-3 md:px-12 border-b w-full border-white text-white flex justify-between items-center font-raleway ${currentScrollY > 0 ? 'bg-gray-900 transition-all duration-300' : ''}`">
      <div class="flex items-center gap-1 cursor-pointer" @click="toggleModal">
        <div class="uppercase font-bold text-[14px] tracking-widest">{{ currentLanguage }}</div>
        <svg class="w-4 h-5 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
          width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"
            d="m19 9-7 7-7-7" />
        </svg>
      </div>
      <div class="flex items-center gap-4">
        <a href="https://www.linkedin.com/company/hova-ai/about/">
          <div class="hover:text-blue-600 text-white text-[16px]">
            <i class="fab fa-linkedin-in"></i>
          </div>
        </a>
        <a href="https://twitter.com/hova_ai">
          <div class="hover:text-blue-400 text-white text-[16px]">
            <i class="fab fa-twitter"></i>
          </div>
        </a>
        <a href="https://www.instagram.com/hova.ai/">
          <div class="hover:text-purple-500 text-white text-[16px]">
            <i class="fab fa-instagram"></i>
          </div>
        </a>
        <a class="text-[15px] tracking-widest font border-l-2 border-yellow-400 pl-4"
          href="mailto:info@hova.ai">info@hova.ai</a>
      </div>
    </div>

    <transition name="slide-fade">
      <div class="bg-white px-8 md:px-12 py-3 shadow-lg md:flex justify-between items-center" v-if="currentScrollY > 0">
        <h1 @click="this.$scrollToEl('.home')"
          class="text-3xl font-extrabold tracking-widest uppercase font-bitter md:text-left text-center">
          Hova <span class="text-yellow-500">AI</span>
        </h1>
        <div class="hidden md:flex h-full items-center gap-6">
          <div
            class="cursor-pointer tracking-widest font-semibold text-sm text-gray-900 hover:text-yellow-500 flex items-center justify-center h-full"
            v-for="item in items" @click="this.$scrollToEl(`${item.link.toLowerCase()}`)" :key="item.link">
            {{ item.title.toUpperCase() }}
          </div>
        </div>
      </div>
    </transition>

    <div v-if="isModalOpen" class="fixed inset-0 bg-black/70 flex items-center justify-center z-50">
      <div class="bg-gray-900 rounded-lg w-80 text-white bg-opacity-80">
        <div class="flex justify-between items-center py-3 px-4 border-b">
          <h3 class="uppercase font-raleway text-[14px] tracking-wider">Select Language</h3>
          <svg @click="toggleModal" class="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
            width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M6 18 17.94 6M18 18 6.06 6" />
          </svg>
        </div>
        <div class="space-y-3 py-6">
          <button v-for="(label, code) in languages" :key="code" @click="changeLanguage(code)"
            class="w-full py-2 px-4 rounded-md text-sm font-medium tracking-wider flex gap-1 items-center justify-between"
            :class="code === $i18n.locale ? 'text-yellow-500' : ''">
            {{ label }}
            <!-- Show checkmark for selected language -->
            <span v-if="code === $i18n.locale" class="text-yellow-500">
              <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M5 11.917 9.724 16.5 19 7.5" />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "HwHeader",
  props: {
    isHome: { type: Boolean, default: true },
    currentScrollY: { type: Number, default: 0 }
  },
  data() {
    return {
      items: [
        { title: "Home", link: ".home" },
        { title: "About us", link: ".about" },
        { title: "Solutions", link: ".solutions" },
        // { title: "Partners", link: ".partners" },
      ],
      privacy: [
        { title: "Home", link: '/' },
        { title: "Privacy Policy", link: '/privacy' },
        { title: "Terms of Service", link: '/terms' },
      ],
      isModalOpen: false,
      currentLanguage: "English", // Default language name
      languages: {
        en: "English",
        fr: "Français",
        es: "Español"
      },
      currentScrollY: 0
    };
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    changeLanguage(code) {
      this.$i18n.locale = code; // Change the language
      this.currentLanguage = this.languages[code]; // Update displayed language
      this.isModalOpen = false; // Close the modal
    },
    scrollToEl(index) {
      const element = document.querySelector(index);

      this.$smoothScroll({
        scrollTo: element,
        duration: 1500,
        offset: -70,
        updateHistory: true,
      });
    },
    handleScroll() {
      this.currentScrollY = window.scrollY
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style scoped lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
