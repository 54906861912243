<template>
  <div>
    <div class="bg-gray-900 min-h-[calc(100vh-180px)] flex items-center sm:px-4 sm:min-h-[75vh]">
      <div class="w-full md:w-[60%] xl:w-[50%] mx-auto my-10">
        <div class="grid md:grid-cols-2 md:gap-[100px] gap-10">
          <div class="">
            <div class="mb-8">
              <div class="text-yellow-400 text-4xl font-extrabold tracking-widest font-bitter">{{ $t(`contact.title`) }}
              </div>
              <div class="text-gray-400 mt-3 w-[70%] sm:w-[90%]">
                {{ $t(`contact.description`) }}
              </div>
            </div>
            <div class="flex items-start mb-10" v-for="item in contact" :key="item.title">
              <div class="border-2 border-gray-400 w-8 h-8 mt-2 mr-5 items-center flex justify-center">
                <i class="text-gray-400 text-[14px]" :class="item.icon"></i>
              </div>
              <div class="">
                <span class="text-gray-100 font-medium text-xl">{{ $t(`contact.${item.key}.title`) }}</span><br />
                <span class="text-gray-400 font-medium tracking-wider text-[13px]">{{
                  $t(`contact.${item.key}.description`) }}</span><br />
                <a :href="item.link" target="_blank" rel="noopener noreferrer" class="text-gray-100 text-[15px]">{{
                  item.linkLabel }}</a>
              </div>
            </div>

            <div class="">
              <div class="text-gray-100 font-medium text-xl">{{ $t(`contact.follow`) }}</div>
              <div class="flex mt-4">
                <a href="https://www.linkedin.com/company/hova-ai/about/" className="mr-7">
                  <div
                    class="border hover:bg-yellow-400 w-9 h-9 flex items-center justify-center text-gray-100 hover:bg-opacity-60">
                    <i class="fab fa-linkedin-in"></i>
                  </div>
                </a>
                <a href="https://twitter.com/hova_ai" className="mr-7">
                  <div
                    class="border hover:bg-yellow-400 w-9 h-9 flex items-center justify-center text-gray-100 hover:bg-opacity-60">
                    <i class="fab fa-twitter"></i>
                  </div>
                </a>
                <a href="https://www.instagram.com/hova.ai/" className="mr-7">
                  <div
                    class="border hover:bg-yellow-400 w-9 h-9 flex items-center justify-center text-gray-100 hover:bg-opacity-60">
                    <i class="fab fa-instagram"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="form">
            <div class="text-gray-400">
              {{ $t(`contact.form.description`) }}
            </div>
            <div class="input flex flex-col h-[70px] mt-8">
              <label for="" class="text-gray-100 mb-2">{{ $t(`contact.form.fields.full_name`) }}</label>
              <input v-model="name" type="text" class="outline-none border bg-transparent py-1 px-3 text-gray-100" />
              <span v-if="errors.name" class="text-red-500 text-sm mt-1">{{ errors.name }}</span>
            </div>
            <div class="input flex flex-col h-[70px] mt-6">
              <label for="" class="text-gray-100 mb-2">{{ $t(`contact.form.fields.email`) }}</label>
              <input v-model="email" type="email"
                class="outline-none border border-gray-100 bg-transparent py-1 px-3 text-gray-100" />
              <span v-if="errors.email" class="text-red-500 text-sm mt-1">{{ errors.email }}</span>
            </div>
            <div class="input flex flex-col h-[150px] mt-6">
              <label for="" class="text-gray-100 mb-2">{{ $t(`contact.form.fields.message`) }}</label>
              <textarea v-model="message" rows="3"
                class="outline-none border border-gray-100 bg-transparent py-1 px-3 text-gray-100"></textarea>
              <span v-if="errors.message" class="text-red-500 text-sm mt-1">{{ errors.message }}</span>
            </div>
            <div v-if="responseMessage !== ''"
              :class="`${success ? 'bg-green-500' : 'bg-red-500'} text-gray-100 bg-opacity-35 p-4 mb-4 text-center`">
              {{ responseMessage }}
            </div>
            <button v-if="!success" :disabled="loading" @click="submitForm()"
              class="bg-gray-400 w-full h-[38px] hover:bg-gray-950 shadow-lg uppercase hover:text-gray-50 relative">
              <span :class="{ 'opacity-0': loading }">{{ $t(`contact.form.fields.submit`) }}</span>
              <div v-if="loading" class="absolute inset-0 flex items-center justify-center">
                <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-950 sm:px-4">
      <div class="uppercase text-[13px] w-full md:w-[60%] xl:w-[50%] mx-auto py-5 text-gray-400 flex justify-between">
        <span>HOVA.AI &copy; {{ new Date().getFullYear() }}</span>
        <span>
          <a class="mr-8" href="/privacy" rel="noopener noreferrer">Privacy</a>
          <a href="/terms" rel="noopener noreferrer">Terms</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "HwContact",
  data() {
    return {
      contact: [
        {
          icon: "far fa-envelope",
          key: "chat",
          title: "Chat to us",
          subtitle: "Our team is here to help you!",
          link: "mailto:info@hova.ai",
          linkLabel: "info@hova.ai",
        },
        {
          icon: "fas fa-phone",
          key: "phone",
          title: "Phone",
          subtitle: "Mon - Sat, 9AM - 7PM",
          link: "tel:+250793090500",
          linkLabel: "+250 793 090 500",
        },
      ],
      name: '',
      email: '',
      message: '',
      loading: false,
      success: false,
      responseMessage: '',
      apiBaseUrl: process.env.VUE_APP_BACKEND_API,
      errors: {
        name: '',
        email: '',
        message: ''
      }
    };
  },
  methods: {
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    validateForm() {
      let isValid = true;
      this.errors = {
        name: '',
        email: '',
        message: ''
      };

      if (!this.name.trim()) {
        this.errors.name = 'Name is required';
        isValid = false;
      }

      if (!this.email.trim()) {
        this.errors.email = 'Email is required';
        isValid = false;
      } else if (!this.validateEmail(this.email)) {
        this.errors.email = 'Please enter a valid email';
        isValid = false;
      }

      if (!this.message.trim()) {
        this.errors.message = 'Message is required';
        isValid = false;
      }

      return isValid;
    },
    async submitForm() {
      if (!this.validateForm()) {
        this.success = false;
        return;
      }

      this.loading = true;
      this.responseMessage = '';
      this.success = false;

      try {
        const response = await axios.post(`${this.apiBaseUrl}/api/v1/system/mailer/send/info`, {
          email: this.email,
          full_name: this.name,
          message: this.message
        });

        if (response.status === 200) {
          this.success = true;
          this.responseMessage = response.data.message || "Email sent successfully!"
          this.name = '';
          this.email = '';
          this.message = '';
        }
      } catch (err) {
        this.responseMessage = "Failed to send message. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    validateField(field) {
      switch (field) {
        case 'name':
          if (this.name.trim()) {
            this.errors.name = '';
          }
          break;
        case 'email':
          if (this.email.trim() && this.validateEmail(this.email)) {
            this.errors.email = '';
          }
          break;
        case 'message':
          if (this.message.trim()) {
            this.errors.message = '';
          }
          break;
      }
    },
  },
  watch: {
    name() {
      this.validateField('name');
    },
    email() {
      this.validateField('email');
    },
    message() {
      this.validateField('message');
    }
  }
};
</script>

<style lang="scss" scoped></style>
