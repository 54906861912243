import { createI18n } from 'vue-i18n';

// Define translation messages
const messages = {
    en: {
        herobanner: {
            title: {
                1: 'We are',
                2: 'expert data analytics consultants.',
                3: 'unlocking insights.',
            },
            subtitle: 'From predictive analytics to actionable strategies, our team delivers innovative solutions tailored to your needs.'
        },
        menu: {
            1: "Our Achievements",
            2: "Contact Us",
            3: "Our Solutions",
            4: "Our Partners",
        },
        history: {
            title: "More about",
            description: {
                1: "At HOVA AI, we are experts in data analytics, transforming raw data into actionable insights that help businesses grow and thrive.",
                2: "Founded by Arsene Muhire and Xavier Ncuti, our story started in university, where we designed tools to simplify management systems.",
            },
            mission: {
                title: 'Our vision',
                subtitle: 'To transform data into actionable insights, empowering businesses/organizations to adopt smarter, technology-driven strategies.',
            },
            vision: {
                title: 'Our mission',
                subtitle: 'To provide innovative AI-driven solutions that empower informed decision-making and business formalization.',
            }
        },
        achievement: {
            title: "Our innovative approach has earned us recognition",
            1: {
                title: "CMU Africa - IIL",
                description: `The CMU Africa Industry Innovation Lab provides a collaborative environment where startups receive
              mentorship and industry insights to refine their solutions. HOVA AI leveraged this platform to gain
              expertise in customer service, sales, and financial management, critical areas for scaling its business.
              The lab fosters innovation by bridging academia and real-world entrepreneurship.`
            },
            2: {
                title: "Hanga Pitch Fest '23",
                description: `Hanga Pitch Fest '23 was a prestigious competition celebrating innovation and entrepreneurship in Africa.
              HOVA AI emerged as the second-place winner, securing a prize for its groundbreaking AI-driven
              solutions. The event served as a platform to showcase transformative ideas, fostering connections between
              innovators and investors.`
            },
            description: `However beyond awards and learning, we are dedicated to equipping merchants with the tools they need to succeed,
            drive growth, and shape Africa’s digital transformation. At HOVA AI, we are not just consultants; we are
            catalysts for change.`
        },
        partners: {
            title: {
                1: "We worked with the best partners",
                2: "Our partners",
                3: "Our clients",
            }
        },
        contact: {
            title: "Get in touch",
            description: "We’d love to hear from you. Our team is always here to chat.",
            chat: {
                title: "Chat to us",
                description: "Our team is here to help you!"
            },
            email: "info@hova.ai",
            phone: {
                title: "Call us",
                description: "Mon - Sat, 9AM - 7PM"
            },
            follow: "Follow us",
            form: {
                title: "Do you have any enquiries?",
                description: "Fill the form below to reach out directly. Our team will get back to you ASAP!",
                fields: {
                    full_name: "Full Name",
                    email: "Email",
                    message: "Message",
                    submit: "Send your message"
                }
            }
        }
    },
    fr: {
        herobanner: {
            title: {
                1: 'Nous sommes',
                2: 'des consultants experts en analyse de données',
                3: 'révélant des perspectives.',
            },
            subtitle: "De l'analyse prédictive aux stratégies concrètes, notre équipe propose des solutions innovantes adaptées à vos besoins."
        },
        menu: {
            1: "Nos Réalisations",
            2: "Contactez-nous",
            3: "Nos Solutions",
            4: "Nos Partenaires",
        },
        history: {
            title: "En savoir plus sur",
            description: {
                "1": "Chez HOVA AI, nous sommes experts en analyse de données, transformant les données brutes en informations exploitables qui aident les entreprises à croître et à prospérer.",
                "2": "Fondée par Arsene Muhire et Xavier Ncuti, notre histoire a débuté à l'université, où nous avons conçu des outils pour simplifier les systèmes de gestion.",
            },
            mission: {
                title: 'Notre vision',
                subtitle: 'Transformer les données en informations exploitables, permettant aux entreprises/organisations d’adopter des stratégies plus intelligentes et axées sur la technologie.',
            },
            vision: {
                title: 'Notre mission',
                subtitle: 'Fournir des solutions innovantes basées sur l’intelligence artificielle pour favoriser des prises de décision éclairées et la formalisation des entreprises.',
            },
        },
        achievement: {
            title: "Notre approche innovante nous a valu une reconnaissance",
            1: {
                title: "CMU Africa - IIL",
                description: "Le CMU Africa Industry Innovation Lab offre un environnement collaboratif où les startups reçoivent des mentorats et des informations sectorielles pour affiner leurs solutions. HOVA AI a utilisé cette plateforme pour acquérir une expertise en service client, ventes et gestion financière, des domaines clés pour l'expansion de son entreprise. Le laboratoire favorise l'innovation en reliant le monde académique et l'entrepreneuriat réel."
            },
            2: {
                title: "Hanga Pitch Fest '23",
                description: "Le Hanga Pitch Fest '23 était une compétition prestigieuse célébrant l'innovation et l'entrepreneuriat en Afrique. HOVA AI est sorti vainqueur en deuxième position, remportant un prix de 20 000 $ pour ses solutions révolutionnaires basées sur l'IA. L'événement a servi de plateforme pour mettre en avant des idées transformantes, favorisant les connexions entre innovateurs et investisseurs."
            },
            description: "Cependant, au-delà des récompenses et de l'apprentissage, nous sommes déterminés à fournir aux commerçants les outils dont ils ont besoin pour réussir, stimuler la croissance et façonner la transformation numérique de l'Afrique. Chez HOVA AI, nous ne sommes pas seulement des consultants ; nous sommes des catalyseurs de changement."
        },
        partners: {
            title: {
                1: "Nous avons travaillé avec les meilleurs partenaires",
                2: "Nos partenaires",
                3: "Nos clients"
            }
        },
        contact: {
            title: "Contactez-nous",
            description: "Nous serions ravis d'avoir de vos nouvelles. Notre équipe est toujours disponible pour discuter.",
            chat: {
                title: "Discutez avec nous",
                description: "Notre équipe est là pour vous aider !"
            },
            email: "info@hova.ai",
            phone: {
                title: "Téléphone",
                description: "Lun - Sam, 9h - 19h"
            },
            follow: "Suivez-nous",
            form: {
                title: "Vous avez des questions ?",
                description: "Remplissez le formulaire ci-dessous pour nous contacter directement. Notre équipe vous répondra dès que possible !",
                fields: {
                    full_name: "Nom complet",
                    email: "E-mail",
                    message: "Message",
                    submit: "Envoyez votre message"
                }
            }
        }

    },
    es: {
        herobanner: {
            title: {
                1: 'Somos',
                2: 'consultores expertos en análisis de datos',
                3: 'que desbloquean perspectivas.',
            },
            subtitle: "Desde el análisis predictivo hasta estrategias accionables, nuestro equipo ofrece soluciones innovadoras adaptadas a sus necesidades."
        },
        menu: {
            1: "Nuestros Logros",
            2: "Contáctanos",
            3: "Nuestras Soluciones",
            4: "Nuestros socios",
        },
        history: {
            title: "Más sobre",
            description: {
                1: "En HOVA AI, somos expertos en análisis de datos, transformando datos en bruto en información procesable que permite a las empresas desarrollarse y prosperar.",
                2: "Fundada por Arsene Muhire y Xavier Ncuti, nuestra historia comienza en la universidad, donde diseñamos herramientas para simplificar los sistemas de gestión.",
            },
            mission: {
                title: 'Nuestra visión',
                subtitle: 'Transformar los datos en información procesable, empoderando a las empresas/organizaciones para adoptar estrategias más inteligentes impulsadas por la tecnología.',
            },
            vision: {
                title: 'Nuestra misión',
                subtitle: 'Proporcionar soluciones innovadoras impulsadas por la inteligencia artificial que permitan tomar decisiones informadas y formalizar los negocios.',
            },
        },
        achievement: {
            title: "Nuestro enfoque innovador nos ha valido el reconocimiento",
            1: {
                title: "CMU Africa - IIL",
                description: "El CMU Africa Industry Innovation Lab proporciona un entorno colaborativo donde las startups reciben mentoría y perspectivas de la industria para perfeccionar sus soluciones. HOVA AI aprovechó esta plataforma para adquirir experiencia en servicio al cliente, ventas y gestión financiera, áreas clave para escalar su negocio. El laboratorio fomenta la innovación al conectar el mundo académico con el emprendimiento real."
            },
            2: {
                title: "Hanga Pitch Fest '23",
                description: "Hanga Pitch Fest '23 fue una competencia prestigiosa que celebró la innovación y el emprendimiento en África. HOVA AI emergió como el segundo lugar, asegurando un premio por sus soluciones innovadoras impulsadas por IA. El evento sirvió como una plataforma para mostrar ideas transformadoras, fomentando conexiones entre innovadores e inversionistas."
            },
            description: "Sin embargo, más allá de los premios y el aprendizaje, estamos comprometidos a proporcionar a los comerciantes las herramientas que necesitan para tener éxito, impulsar el crecimiento y dar forma a la transformación digital de África. En HOVA AI, no solo somos consultores; somos catalizadores del cambio."
        },
        partners: {
            title: {
                1: "Hemos trabajado con los mejores socios",
                2: "Nuestros socios",
                3: "Nuestros clientes"
            }
        },
        contact: {
            title: "Ponte en contacto",
            description: "Nos encantaría saber de ti. Nuestro equipo siempre está disponible para charlar.",
            chat: {
                title: "Habla con nosotros",
                description: "¡Nuestro equipo está aquí para ayudarte!"
            },
            email: "info@hova.ai",
            phone: {
                title: "Teléfono",
                description: "Lun - Sáb, 9AM - 7PM"
            },
            follow: "Síguenos",
            form: {
                title: "¿Tienes alguna consulta?",
                description: "Rellena el formulario a continuación para contactarnos directamente. ¡Nuestro equipo te responderá lo antes posible!",
                fields: {
                    full_name: "Nombre completo",
                    email: "Correo electrónico",
                    message: "Mensaje",
                    submit: "Envía tu mensaje"
                }
            }
        }
    },
};

// Create i18n instance
const i18n = createI18n({
    locale: 'en', // Default locale
    fallbackLocale: 'en', // Fallback locale
    messages, // Translation messages
});

export default i18n;
