<template>
  <section class="relative bg-white text-gray-800">
    <!-- Content Section -->
    <div class="container mx-auto px-6 md:px-12 lg:px-16 md:py-12">
      <div class="flex md:flex-row-reverse flex-col-reverse gap-12">
        <!-- Text Content -->
        <div class="md:w-1/2">
          <h2 class="text-3xl md:text-4xl font-bold mb-10 font-bitter md:text-left text-center leading-relaxed"
            data-aos="fade-up">
            {{ $t(`achievement.title`) }}
          </h2>
          <div class="md:w-1/2 md:hidden justify-center md:p-6 mb-8 md:mb-0" data-aos="fade-up" data-aos-delay="200">
            <img src="../assets/hanga-hova.jpeg" alt="HOVA AI Team"
              class="shadow-lg object-cover w-full h-full object-right transform transition-all duration-500 hover:scale-105" />
          </div>
          <div v-for="(item, index) in achievements" :key="index" data-aos="fade-up"
            :data-aos-delay="300 + (index * 200)">
            <div class="font-raleway font-bold flex items-center gap-3">
              <div class="w-2 h-2 border border-yellow-500 rounded-full bg-yellow-500"></div>
              <div class="text-yellow-500 text-[18px] uppercase mt-1 tracking-widest">
                {{ $t(`achievement.${item}.title`) }}
              </div>
            </div>
            <div class="border-l border-yellow-500 px-4 py-2 ml-1 font-raleway font-light">
              {{ $t(`achievement.${item}.description`) }}
            </div>
          </div>
          <!-- <div>
            <div class="font-raleway font-bold flex items-center gap-3">
              <div class="w-2 h-2 border border-yellow-500 rounded-full bg-yellow-500"></div>
              <div class="text-yellow-500 text-[18px] uppercase tracking-widest">
                CMU Africa - IIL
              </div>
            </div>
            <div class="border-l border-yellow-500 px-4 py-2 ml-1 font-raleway font-light">
              The CMU Africa Industry Innovation Lab provides a collaborative environment where startups receive
              mentorship and industry insights to refine their solutions. HOVA AI leveraged this platform to gain
              expertise in customer service, sales, and financial management, critical areas for scaling its business.
              The lab fosters innovation by bridging academia and real-world entrepreneurship.
            </div>
          </div>
          <div>
            <div class="font-raleway font-bold flex items-center gap-3">
              <div class="w-2 h-2 border border-yellow-500 rounded-full bg-yellow-500"></div>
              <div class="text-yellow-500 text-[18px] uppercase tracking-widest">
                Hanga Pitch Fest '23
              </div>
            </div>
            <div class="border-l border-yellow-500 px-4 py-2 ml-1 font-raleway font-light">
              Hanga Pitch Fest '23 was a prestigious competition celebrating innovation and entrepreneurship in Africa.
              HOVA AI emerged as the second-place winner, securing a $20,000 prize for its groundbreaking AI-driven
              solutions. The event served as a platform to showcase transformative ideas, fostering connections between
              innovators and investors.
            </div>
          </div> -->
          <p class="text-xl leading-relaxed my-10 font-raleway md:text-left text-center" data-aos="fade-up"
            data-aos-delay="700">
            {{ $t(`achievement.description`) }}
          </p>
        </div>
        <!-- Image Content -->
        <div class="md:w-1/2 justify-center md:flex hidden" data-aos="fade-up" data-aos-delay="400">
          <img src="../assets/hanga-hova.jpeg" alt="HOVA AI Team"
            class="shadow-lg object-cover w-full h-full object-right transform transition-all duration-500 hover:scale-105" />
        </div>
      </div>
    </div>
    <!-- Wave Design -->
    <div class="absolute bottom-0 w-full">
      <svg viewBox="0 0 1440 320" class="w-full h-[200px] md:h-[300px]" xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none">
        <path fill="#f3f4f6"
          d="M0,224L48,202.7C96,181,192,139,288,122.7C384,107,480,117,576,138.7C672,160,768,192,864,192C960,192,1056,160,1152,144C1248,128,1344,128,1392,128L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" />
      </svg>
    </div>
  </section>
</template>

<script>
import { animationMixin } from '@/mixins/animationMixin';

export default {
  name: "Achievement",
  mixins: [animationMixin],
  data() {
    return {
      historyItems: [
        { key: 'vision' },
        { key: 'mission' },
      ],
      achievements: [1, 2]
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  z-index: 10;
}
</style>
