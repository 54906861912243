<template>
 <div class="">
  <Herobanner class="home" :currentScrollY="currentScrollY" />
  <About ref="about" class="about"/>
  <Achievement />
  <Partners ref="partners" class="partners" />
  <Solutions ref="solutions" class="solutions" />
  <Contact class="contact" />
 </div>
</template>

<script>
import Onboard from '@/components/Onboard.vue';
import About from '@/components/About.vue'
import Services from '@/components/Services.vue';
import Products from '@/components/Products.vue';
import Team from '@/components/Team.vue';
import Testimonies from '@/components/Testimonies.vue';
import Contact from '@/components/Contact.vue';
import Herobanner from '@/components/Herobanner.vue';
import Achievement from '@/components/Achievement.vue';
import Partners from '@/components/Partners.vue';
import Solutions from '@/components/Solutions.vue';

export default {
  name: 'HwHome',
  components: {
    Onboard,
    About,
    Services,
    Products,
    Team,
    Testimonies,
    Contact,
    Herobanner,
    Achievement,
    Partners,
    Solutions,
  },
  data() {
    return {
      currentScrollY: 0
    }
  },
  methods: {
    handleScroll() {
      this.currentScrollY = window.scrollY
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
</script>

<style scoped lang="scss"></style>

